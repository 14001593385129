.container {
  font-size: var(--size-text-small);
}

.controlsTable {
  width: auto;
  border-collapse: separate;
  border-spacing: 0;

  input {
    margin: 0;
  }

  td {
    border: 0;
    text-align: left;
  }
}

.apBrowser {
  display: inline-flex;
  align-items: center;

  input {
    margin-right: var(--spacing-small);
  }

  &:not(:first-child) {
    margin-left: var(--spacing-big);
  }
}

.smallPadding {
  padding: var(--spacing-small);
}

.header {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  font-size: var(--size-text-regular);

  > * {
    margin: var(--spacing-regular);
    color: var(--color-text-emphasis);
  }
}

.alignMiddle {
  display: flex;
  align-items: center;
}

.no45xxNote {
  color: var(--color-text-minor);
  padding: var(--spacing-regular);
}

.minor {
  color: var(--color-text-minor);
  font-size: var(--size-text-tiny);
}

.cc-error {
  color: var(--color-text-error);
}
.cc-success {
  color: var(--color-text-success);
}
.cc-warning {
  color: var(--color-text-warning);
}
.cc-info {
  color: var(--color-text-info);
}
.cc-minor {
  color: var(--color-text-secondary);
}

.center {
  text-align: center;
}

.regularMargin {
  margin: var(--spacing-regular);
}
