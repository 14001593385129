.infoText {
  margin: var(--spacing-regular);
  font-size: var(--size-text-small);
  color: var(--color-text-minor);
  vertical-align: middle;
}

.subInfo {
  font-size: var(--size-text-small);
  color: var(--color-text-minor);
  vertical-align: middle;
}

.alignChildTop > * {
  vertical-align: top;
}

.radioOptionsContainer {
  margin: var(--spacing-regular) 0 var(--spacing-big) 0;
}

.radioOptionContainer {
  display: flex;
  cursor: pointer;
  align-items: center;

  > *:last-child {
    > *:first-child {
      color: var(--color-text-emphasis);
    }
    > *:last-child {
      font-size: var(--size-text-small);
      color: var(--color-text-minor);
    }
  }
}

.thresholdPicker {
  > *:first-child {
    margin: var(--spacing-regular);
  }
  input[type='number'] {
    width: var(--size-mega-giant);
  }
}

.cardsListItem {
  display: block;

  & > * {
    vertical-align: middle;
    cursor: pointer;
  }
}

.inlineTable {
  display: inline-table;
}

.continuousTrafficPurchase {
  > * {
    margin: var(--spacing-regular);
  }
}

.inputGroup {
  margin: var(--spacing-big) var(--spacing-regular) var(--spacing-regular)
    var(--spacing-regular);
  > *:first-child {
    color: var(--color-text-emphasis);
    margin-bottom: var(--spacing-small);
    font-size: var(--size-text-small);
  }
}
