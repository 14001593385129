.groupHeaderRow {
  font-weight: bold;
}

.groupHeaderCell {
  padding: var(--spacing-small);
  > * {
    vertical-align: middle;
  }
}

.groupIcon {
  display: inline-block;
  width: var(--size-small);
  height: var(--size-small);
  margin-right: var(--spacing-small);
  border-radius: var(--size-small);
}
