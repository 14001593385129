@import 'const';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--full-height);
  text-align: center;

  > * {
    margin: var(--spacing-regular);
  }
}

.content {
  max-width: $maxReadableBlock;
}

.center {
  text-align: center;
}

.icons {
  a {
    text-decoration: none;
    color: inherit;
  }
}

.footer {
  padding: var(--spacing-big);
  font-size: var(--size-text-small);
  color: var(--color-text-minor);
}
