.card {
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-emphasis);
  margin: var(--spacing-regular);
  padding: var(--spacing-regular);
  margin-bottom: 0;
  text-align: left;
  box-sizing: border-box;
  clear: both;

  &:last-child {
    margin-bottom: var(--spacing-regular);
  }

  &.margin-half-regular {
    margin: calc(var(--spacing-regular) / 2);
  }

  &.margin-top-none {
    margin-top: 0;
  }

  &.hoverable {
    cursor: pointer;
    margin: calc(var(--spacing-regular) - 2px);
    transition: var(--transition-default);
    &:hover {
      background-color: var(--color-background-selected);
    }
  }

  > div {
    position: relative;
  }

  &.padding-big > div {
    padding: var(--spacing-big);
  }

  &.hoverable > div {
    border-radius: var(--border-radius-small);
  }
}
