.container {
  text-align: center;
  margin: var(--spacing-huge) var(--spacing-regular);
  font-size: var(--size-text-small);
  color: var(--color-text-minor);
}

.textContainer {
  text-decoration: underline;
}
