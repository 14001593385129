@import 'const';

.dialogContainer {
  max-width: 600px;
  min-width: 50vw;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  transition: var(--transition-default);
  z-index: $zIndexUiOverlay;
  background-color: var(--color-background-overlay);
  cursor: pointer;

  > * {
    cursor: initial;
  }
}

.buttonsContainer {
  text-align: right;
}

.overlayInit {
  opacity: 0 !important;
}

.content {
  overflow: auto;
  max-height: calc(80vh - var(--spacing-big) * 10);
  &.padded {
    margin: var(--spacing-big) 0;
  }
}

.card {
  max-width: 80vw;
  max-height: 80vh;
}
