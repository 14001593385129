.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.arrowSub {
  font-size: var(--size-text-micro);
  height: 0;
  text-align: center;
  overflow: visible;
}

.option {
  transition: var(--transition-default);
  cursor: pointer;
  min-width: var(--size-giant);
  text-align: center;
  color: var(--color-text-secondary);
  font-size: var(--size-text-small);
  padding: var(--spacing-small) var(--spacing-tiny);
  user-select: none;

  &:hover {
    background-color: var(--color-background-selected);
  }

  &.selected {
    font-weight: bold;
    background-color: var(--color-background-selected);
  }
}

.icon {
  color: var(--color-background-accent);
}
