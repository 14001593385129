@import 'const';

.warning {
  color: var(--color-text-warning);
}

.ok {
  color: var(--color-text-success);
}

.colorSecondary {
  color: var(--color-text-secondary);
}

.textSecondary {
  color: var(--color-text-secondary);
  font-size: var(--size-text-small);
}

.containerCenter {
  max-width: $tabletScreenWidth;
  margin: 0 auto;
}
