@import 'const';

html,
body {
  display: block;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--color-background-primary);
  font-size: var(--size-text-regular);
  color: var(--color-text-primary);
}

.color-warning {
  color: var(--color-text-warning);
}

pre {
  margin: 0;
  display: inline-block;
}

fieldset {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

span {
  vertical-align: middle;
}

input,
textarea,
button,
select,
a.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-infinite);
  text-decoration: none;
  border: none;
  box-shadow: var(--box-shadow-default);
  font: inherit;
  outline: none;
  margin: var(--spacing-regular);
  padding: var(--spacing-regular) var(--spacing-big);
  background-color: var(--color-background-emphasis);
  // line-height: 1; // Uglifies ios inputs
  color: var(--color-text-emphasis);
  &:disabled {
    & > * {
      opacity: 0.7 !important;
    }
    color: var(--color-text-primary);
    // color: var(--color-text-secondary) !important;
    background-color: var(--background-element-disabled) !important;
    cursor: not-allowed !important;
  }
}

// Fixes recharts z-index.
.recharts-tooltip-wrapper {
  z-index: $zIndexTooltips !important;
}

.toast {
  color: inherit !important;
  text-shadow: inherit !important;
  .body {
    border-radius: var(--border-radius-big) !important;
    padding: var(--spacing-regular) !important;
    box-shadow: var(--box-shadow-default) !important;
    color: inherit !important;
    text-shadow: inherit !important;

    &.info {
      background-color: var(--color-background-primary) !important;
    }
    &.done {
      background-color: var(--color-background-success) !important;
    }
    &.error {
      background-color: var(--color-background-error) !important;
    }
    &.warning {
      background-color: var(--color-background-warning) !important;
    }
  }
}

textarea {
  display: block;
  width: 100%;
  border-radius: var(--border-radius-regular);
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 0;
  padding: var(--spacing-regular);
  min-height: calc(var(--size-giant) * 2.1);
}

select {
  padding-right: var(--spacing-huge);
  background: var(--color-background-emphasis);
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - var(--spacing-regular));
  background-position-y: 50%;

  &:disabled {
    background-image: none;
    padding-right: var(--spacing-big);
    pointer-events: none;
  }
}

input:not([type='submit']) {
  box-shadow: var(--box-shadow-inset);
}

input[type='submit'],
button,
select,
a.button {
  font-weight: bold;
  &:not(select) {
    background-color: var(--color-background-accent);
    color: var(--color-text-alt-primary);
  }
  &.secondary {
    color: var(--color-text-primary);
    background: var(--color-background-emphasis);
  }
  &.special {
    background: var(--color-background-accent-secondary);
  }
  &.borderless {
    margin: 0;
    &:not(:hover) {
      color: inherit;
      background: none;
      box-shadow: var(--box-shadow-none);
    }
  }
  &.danger {
    background: var(--color-text-error);
  }
  cursor: pointer;
  * {
    vertical-align: middle;
  }
}

*.nowrap {
  white-space: nowrap;
}

button {
  &.nowrap {
    white-space: nowrap;
  }
}

input[type='radio'] {
  position: relative;
  width: var(--size-regular);
  height: var(--size-regular);
  padding: 0;
  &:after {
    transition: var(--transition-default);
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    bottom: 3px;
    right: 3px;
    border-radius: var(--border-radius-infinite);
  }
  &:checked:after {
    background-color: var(--color-text-success);
  }
}

label {
  cursor: pointer;
}

input[type='checkbox'] {
  position: relative;
  width: var(--size-big);
  height: var(--size-big);
  padding: 0;
  margin: 0;
  border-radius: var(--border-radius-tiny);

  &:not(:disabled) {
    cursor: pointer;
  }

  &::after {
    position: absolute;
    content: '';
    right: 15%;
    left: 45%;
    top: 15%;
    bottom: 15%;
    border: 5px solid var(--color-text-success);
    border-top: 0;
    border-left: 0;
    transform: translate(-30%, -11%) rotate(45deg);
    transition: var(--transition-default);

    opacity: 0;
  }

  &:checked::after {
    opacity: 1;
  }
}

button,
input[type='radio'],
input[type='submit'],
a.button {
  transition: var(--transition-default);
  &:not(:disabled) {
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      transform: var(--transform-hover-default);
    }
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: inherit;
}

.failed-submit input:invalid,
input.invalid {
  box-shadow: var(--box-shadow-inset-invalid);
}

table {
  border-collapse: separate;
  border-spacing: 2px;
  border: none;

  &.card {
    margin: var(--spacing-regular);
  }
}

th {
  background: var(--color-background-secondary);
}

thead {
  tr:first-child {
    th {
      &:first-child {
        border-top-left-radius: var(--border-radius-small);
      }
      &:last-child {
        border-top-right-radius: var(--border-radius-small);
      }
    }
  }
}

tbody:first-child {
  tr:first-child td {
    &:first-child {
      border-top-left-radius: var(--border-radius-small);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius-small);
    }
  }
}

tbody:last-child,
thead:last-child {
  > tr:last-child {
    > td,
    > th {
      &:first-child {
        border-bottom-left-radius: var(--border-radius-small);
      }
      &:last-child {
        border-bottom-right-radius: var(--border-radius-small);
      }
    }
  }
}

th,
td {
  padding: var(--spacing-regular);
  margin: 0;
  border: 0;
}

thead tr:not(:last-child) td,
tbody tr td {
  border-bottom: 1px solid var(--color-background-secondary);
}

tbody:last-child tr:last-child td {
  border-bottom: none;
}

code {
  font-size: 80%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding: var(--spacing-tiny) var(--spacing-small);
  border-radius: 6px;
  background: var(--color-background-secondary);

  &.block {
    display: block;
    white-space: pre;
    overflow: auto;
    background: transparent;
  }
}
