@import 'const';

.containerCenter {
  max-width: $tabletScreenWidth;
  margin: 0 auto;
}

.marginBottom {
  margin-bottom: var(--spacing-big);
}

.headerText {
  @extend .marginBottom;
  > * {
    vertical-align: middle;
  }
}

.infoText {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-regular);
  @extend .marginBottom;
  color: var(--color-text-secondary);
  font-size: var(--size-text-small);
}

.trafficTable {
  font-size: var(--size-text-small);
  & th,
  td {
    padding: var(--spacing-small);
  }
}

.usageContainer {
  padding: calc(var(--spacing-regular) / 2);
  & > div {
    margin: var(--spacing-regular);
    h2 {
      margin-bottom: var(--spacing-regular);
    }
  }
}
