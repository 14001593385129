@import 'const';

.sub {
  color: var(--color-text-minor);
  font-size: var(--size-text-tiny);
}

.p {
  margin: var(--spacing-big) 0 var(--spacing-regular) 0;
}

.error {
  color: var(--color-text-error);
}

.success {
  color: var(--color-text-success);
}

.center {
  text-align: center;
}

.table {
  font-size: var(--size-text-small);
  width: 100%;
  table-layout: fixed;
  th:last-child,
  td:last-child {
    width: calc(var(--size-big));
  }
}

.footer {
  text-align: center;
  margin-top: var(--spacing-giant);
}
