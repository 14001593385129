.allServicesRow {
  font-size: var(--size-text-small);
  transition: var(--transition-default);
  cursor: pointer;
  &:hover {
    background-color: var(--color-background-selected);
  }
}

.middleChildrenAlign > * {
  vertical-align: middle;
}

.settingsContainer {
  font-size: var(--size-text-small);
}

.noWrap {
  white-space: nowrap;
}

.enabled {
  color: var(--color-text-success);
}

.disabled {
  color: var(--color-text-secondary);
}

.minor {
  color: var(--color-text-minor);
}

.container {
  font-size: var(--size-text-small);
}

.header {
  font-size: var(--size-text-regular);
  margin: var(--spacing-regular);
  color: var(--color-text-emphasis);
}
