.switch {
  margin: var(--spacing-regular);
  display: flex;
  // justify-content: center;

  &.noMargin {
    margin: 0;
  }

  .switchBox {
    position: relative;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0;
    background-color: transparent;
    width: calc(var(--size-big) * 2 - 8px);
    height: var(--size-big);

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-text-secondary);
      border-radius: 1000px;
      transition: var(--transition-default);

      &:before {
        position: absolute;
        content: '';
        height: calc(100% - 8px);
        width: calc(50% - 4px);
        left: 4px;
        bottom: 4px;
        background-color: var(--color-background-emphasis);
        border-radius: 1000px;
        transition: var(--transition-default);
      }
    }

    input {
      display: none;

      &:checked + .slider {
        background-color: var(--color-text-success);
      }

      &:checked + .slider:before {
        transform: translateX(calc(100%));
      }

      &:focus + .slider {
        box-shadow: 0 0 1px var(--color-background-accent);
      }
    }
  }

  .label {
    margin-left: var(--spacing-regular);
    flex-grow: 1;
  }
}
