@import 'const';

.containerCenter {
  max-width: $tabletScreenWidth;
  margin: 0 auto;
}

.center {
  text-align: center;
}

.cardHeader {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-regular);

  > *:first-child {
    flex-grow: 1;
  }
  > *:last-child {
    display: flex;
  }
}

.enabled {
  color: var(--color-text-success);
}

.disabled {
  color: var(--color-text-secondary);
}

.emphasis {
  color: var(--color-text-emphasis);
}

.addPropertyButton {
  margin: var(--spacing-regular);
}

.noPropertiesIconContainer {
  margin: var(--spacing-big);
  opacity: 0.3;
  & + button {
    margin-top: 0;
  }
}
