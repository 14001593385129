@import 'const';

.container {
  display: inline-flex;
  vertical-align: middle;
}

.content {
  display: inline-flex;
}

.tooltip {
  position: fixed;
  display: none;
  z-index: $zIndexUiOverlay;
  left: 0;
  top: 0;
  width: auto;
  box-sizing: border-box;
  overflow: hidden;
  padding: var(--spacing-small);
  color: var(--color-text-primary);
  cursor: initial;
  text-align: initial;

  & > * {
    display: inline-block;
    white-space: normal;
    background-color: var(--color-background-emphasis);
    box-shadow: var(--box-shadow-default);
    border-radius: var(--border-radius-small);
    padding: var(--spacing-regular);
    font-size: var(--size-text-small);
    font-weight: normal;
  }
}
