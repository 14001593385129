@import 'const.scss';

$bottom-line-height: 2px;

.header {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  font-weight: bold;
  left: 0;
  right: 0;
  top: 0;
  height: calc(var(--header-height-expr));
  background: var(--background-gradient);
  color: var(--color-text-alt-primary);
  font-size: var(--size-text-huge);
  line-height: 1;
  padding: var(--spacing-regular);
  z-index: $zIndexUi;

  > * {
    vertical-align: top;
  }

  > *:not(:last-child) {
    flex-grow: 1;
  }

  // Header style when logged in as super admin
  &.sa {
    background: var(--background-gradient-sa);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $bottom-line-height;
    background-color: var(--color-background-primary);
    opacity: 25%;
  }
}

.betaBadge {
  font-size: var(--size-text-small);
  color: var(--color-text-alt-primary);
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.rightNavButton {
  display: flex;
  align-items: center;
}

@media screen and (min-width: #{$responsiveMainMenuBreakpointWidth + 1px}) {
  .rightNavButton {
    display: none;
  }
}

.overlay {
  position: fixed;
  left: 0;
  width: 100%;
  top: var(--header-height);
  height: 0;
  opacity: 0;
  z-index: $zIndexUiUnderlay;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
  &.opened {
    opacity: 1;
    height: calc(100% - var(--header-height));
  }
}

.menuBar {
  position: fixed;
  left: 0;
  top: var(--header-height);
  z-index: $zIndexUi;
  height: calc(100% - var(--header-height));
  transition: left 0.3s ease;
  &.opened {
    left: 0;
    box-shadow: var(--box-shadow-default);
  }
}

:global(#root) {
  &:not(.noMenu) {
    margin-left: var(--sidebar-width);
  }
  margin-top: var(--header-height);
}
@media screen and (max-width: #{$responsiveMainMenuBreakpointWidth}) {
  .menuBar {
    left: calc(-1 * var(--sidebar-width));
  }

  :global(#root) {
    margin-left: 0 !important;
  }
}
