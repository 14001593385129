.button {
  vertical-align: middle;

  & > * {
    vertical-align: middle;
  }

  &.compact {
    font-size: var(--size-text-small);
    padding: var(--spacing-small) var(--spacing-regular);
  }
}
