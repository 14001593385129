$tabletScreenWidth: 1000px;
$responsiveMainMenuBreakpointWidth: $tabletScreenWidth;
$maxReadableBlock: 800px;
$zIndexUiUnderlay: 100;
$zIndexUi: 101;
$zIndexUiOverlay: 102;
$zIndexTooltips: 103;

:root,
html {
  --box-shadow-color: #eee;
  --box-shadow-none: 0 0 0 0 rgba(0, 0, 0, 0);
  --box-shadow-default: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --box-shadow-inset: inset 0 1px 3px 0 rgba(0, 0, 0, 0.15),
    inset 0 0 2px 0 rgba(0, 0, 0, 0.06);
  --box-shadow-inset-invalid: inset 0 0 3px 0 rgba(255, 0, 0, 0.8),
    inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  --box-shadow-invalid: 0 0 3px 0 rgba(255, 0, 0, 0.8),
    0 1px 3px 0 rgba(0, 0, 0, 0.1);

  --text-shadow-default: 0 2px 2px rgba(0, 0, 0, 0.15);

  --text-font-family-monospace: monospace;

  --color-text-emphasis: #000;
  --color-text-primary: #555;
  --color-text-secondary: #777;
  --color-text-minor: #999;
  --color-text-alt-primary: #f7f7f7;
  --color-text-alt-secondary: #c7c7c7;

  --color-text-success: #6cb572;
  --color-text-info: #606dc9;
  --color-text-warning: #ec7800;
  --color-text-error: #e26060;

  --color-background-secondary: #e6e6e6;
  --color-background-primary: #fafafa;
  --color-background-emphasis: #fff;
  --color-background-selected: #efedff;
  --color-background-success: #d9fbdc;
  --color-background-accent: #8849bd;
  --color-background-accent-secondary: #2ab4d6;
  --color-background-error: #ffc6c6;
  --color-background-warning: #ffe1be;
  --color-background-overlay: rgba(0, 0, 0, 0.7);

  --background-gradient: linear-gradient(90deg, #7733d5 0%, #8849bd 100%);
  --background-gradient-sa: linear-gradient(
    90deg,
    #6900a5 0%,
    #dd7eff 100%
  ); // Super admin's background
  --background-gradient-element: linear-gradient(
    90deg,
    #7143d2 0%,
    #5e74c8 100%
  );
  --background-element-disabled: #f3f3f3; //linear-gradient(90deg, #9d8ebd 0%, #7e88ad 100%);

  --background-gradient-variant-1: linear-gradient(
    to top,
    var(--color-background-primary),
    #e7ccfd 98%,
    var(--color-background-accent) 99%
  );
  --background-gradient-variant-2: linear-gradient(
    to top,
    var(--color-background-primary),
    rgb(198, 241, 234) 98%,
    rgb(69, 211, 187) 99%
  );
  --background-gradient-variant-3: linear-gradient(
    to top,
    var(--color-background-primary),
    rgb(247, 243, 199) 98%,
    rgb(255, 238, 0) 99%
  );

  --size-text-micro-expr: 9px + 0.05vmin;
  --size-text-tiny-expr: 11px + 0.1vmin;
  --size-text-small-expr: 13px + 0.15vmin;
  --size-text-regular-expr: 16px + 0.25vmin;
  --size-text-big-expr: 21px + 0.5vmin;
  --size-text-huge-expr: 28px + 0.75vmin;
  --size-text-giant-expr: 40px + 1vmin;
  --size-text-mega-giant-expr: 88px + 2vmin;
  --size-text-ultra-giant-expr: 172px + 3.5vmin;
  --size-text-micro: calc(var(--size-text-micro-expr));
  --size-text-tiny: calc(var(--size-text-tiny-expr));
  --size-text-small: calc(var(--size-text-small-expr));
  --size-text-regular: calc(var(--size-text-regular-expr));
  --size-text-big: calc(var(--size-text-big-expr));
  --size-text-huge: calc(var(--size-text-huge-expr));
  --size-text-giant: calc(var(--size-text-giant-expr));
  --size-text-mega-giant: calc(var(--size-text-mega-giant-expr));
  --size-text-ultra-giant: calc(var(--size-text-ultra-giant-expr));

  --size-small: calc(var(--size-text-small));
  --size-regular: calc(var(--size-text-regular-expr));
  --size-big: calc(var(--size-text-big-expr));
  --size-huge: calc(var(--size-text-huge-expr));
  --size-giant: calc(var(--size-text-giant-expr));
  --size-mega-giant: calc(var(--size-text-mega-giant-expr));
  --size-ultra-giant: calc(var(--size-text-ultra-giant-expr));

  --size-column-max-width: 800px;

  --spacing-giant-expr: 42px + 1vmin;
  --spacing-giant: calc(var(--spacing-giant-expr));
  --spacing-huge-expr: 24px + 0.75vmin;
  --spacing-huge: calc(var(--spacing-huge-expr));
  --spacing-big-expr: 16px + 0.5vmin;
  --spacing-big: calc(var(--spacing-big-expr));
  --spacing-regular-expr: 8px + 0.25vmin;
  --spacing-regular: calc(var(--spacing-regular-expr));
  --spacing-small-expr: 4px + 0.1vmin;
  --spacing-small: calc(var(--spacing-small-expr));
  --spacing-tiny-expr: 2px;
  --spacing-tiny: calc(var(--spacing-tiny-expr));

  --border-radius-tiny: 4px;
  --border-radius-small: 8px;
  --border-radius-regular: 14px;
  --border-radius-big: 26px;
  --border-radius-infinite: 999px;

  --transform-hover-default: scale(0.9);
  --transition-default: all 0.3s ease;

  /* Application constants */
  --header-height-expr: var(--size-text-huge-expr) + 3px + 2 *
    var(--spacing-regular);
  --header-height: calc(var(--header-height-expr));
  --sidebar-width: 300px;
  --card-width-in-grid-of-cards: calc(#{$maxReadableBlock} / 2.5);
  // 100vh is ugly on phones/tablets. 80vh is a compromise
  --full-height: calc(80vh - var(--header-height));
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
