.shrinkColumn {
  width: 0.1%;
  white-space: nowrap;
}

.success {
  color: var(--color-text-success);
}

.fail {
  color: var(--color-text-error);
}

.mono {
  font-family: monospace;
}

.summaryTable {
  font-size: var(--size-text-small);
  width: 100%;
}

.actionsContainer {
  white-space: pre;

  > *:not(:first-child) {
    margin-left: var(--spacing-small);
  }
}

.tableHeaderCheckboxContainer {
  text-align: center;

  > input {
    margin: 0;
  }
}
