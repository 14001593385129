.sectionHeader {
  margin-top: var(--spacing-big);
  margin-bottom: var(--spacing-regular);
}

.deliveryStatusTable {
  word-break: break-all;

  th {
    word-break: normal;
  }
}
