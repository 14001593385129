.savedCardsTable {
  width: 100%;
  font-size: var(--size-text-small);
}

.minorCenterText {
  text-align: center;
  color: var(--color-text-minor);
}

.alignRight {
  text-align: right;
}
