.container {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  background: var(--color-background-emphasis);
  margin: var(--spacing-regular);
  padding: var(--spacing-regular);
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-default);
  font-size: var(--size-text-small);
  &.display-content {
    margin: var(--spacing-regular) 0;
  }
  &.display-inline {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
  }
  &.type-info {
    color: var(--color-text-info);
  }
  &.type-success {
    color: var(--color-text-success);
  }
  &.type-warning {
    color: var(--color-text-warning);
  }
  &.type-error {
    color: var(--color-text-error);
  }
  & > *:last-child {
    text-align: start;
  }
}
