.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: var(--spacing-regular);
}

.titleContainer {
  display: flex;
  float: left;
  align-items: flex-start;

  > * {
    display: inline-block;
    padding: 0;
  }
}
