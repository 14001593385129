.infoText {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-regular);
  color: var(--color-text-secondary);
  font-size: var(--size-text-small);
  margin-bottom: var(--spacing-small);
}

.infoColor {
  color: var(--color-text-success);
}

.evenInputsWidth {
  width: 160px;
}

.customValueText {
  font-size: var(--size-text-big);
}

.customInput > * {
  vertical-align: middle;
}
