.radioInput {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--color-text-emphasis);
  }

  input[type='radio']:checked + .labelContent {
    color: var(--color-text-emphasis);
  }

  input[type='radio']:disabled + .labelContent {
    cursor: not-allowed;
  }
}
