.menuItem {
  display: block;
  box-sizing: border-box;
  margin: 0 0 0 2px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top-left-radius: var(--size-big);
  border-bottom-left-radius: var(--size-big);
  border-left: 1px solid transparent;

  > *:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  > a {
    transition: color 0.3s ease;
    padding: var(--spacing-regular);
    // display: block;
    text-decoration: none;
    color: var(--color-text-primary);
    & > .menuItemTextContainer {
      transition: padding 0.3s ease;
    }
    & > span {
      transition: var(--transition-default);
    }
  }
  &.menuItemCurrent,
  &.menuItemInPath {
    > a {
      font-weight: bold !important;
      transition: none;
      color: var(--color-text-emphasis);
    }
  }
  &.menuItemCurrent {
    background-color: var(--color-background-primary);
    border: 1px solid var(--box-shadow-color);
    border-right: none;
  }
  &:not(.menuItemCurrent) > a {
    font-weight: normal;
    cursor: pointer;
  }
  &:not(.menuItemCurrent) > a:hover {
    // padding-right: 0;
    > .menuItemTextContainer {
      padding-left: var(--spacing-regular);
    }
    color: var(--color-text-emphasis);
  }
}

.menuItemTextContainer {
  min-width: 0; // https://stackoverflow.com/a/66689926/2590150
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: var(--spacing-small);
  height: var(--size-big);

  & > * {
    position: relative;
    width: 100%;
  }
}

.textContainer {
  position: relative;
  top: -3px;
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.containerWithWarning {
  position: relative;
}

.warningMessageContainer {
  color: var(--color-text-warning);
  font-size: var(--size-text-tiny);
}

.menuItemIcon {
  float: left;
}

.children {
  padding-left: var(--spacing-regular);

  &.collapsedChildren > * {
    display: none;
  }
}
