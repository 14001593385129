$borderSize: 4px;

.container {
  position: relative;
}

.strip {
  z-index: 1;
  position: absolute;
  bottom: #{$borderSize * -2 + 0.5px}; // calc(-0.8 * var(--spacing-big));
  left: calc(50% - 2px);
  width: 4px;
  height: calc(
    100% - var(--size-giant) + #{$borderSize}
  ); // calc(100% - var(--spacing-big));
  background-color: var(--color-background-secondary);
  transition: var(--transition-default);

  &.done {
    background-color: var(--color-background-accent);
  }
  &.hidden {
    opacity: 0;
  }
}

.step {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin: $borderSize var(--spacing-regular) 0 var(--spacing-regular);
  width: calc(var(--size-giant) - #{$borderSize * 2});
  height: calc(var(--size-giant) - #{$borderSize * 2});
  min-width: calc(var(--size-giant) - #{$borderSize * 2});
  min-height: calc(var(--size-giant) - #{$borderSize * 2});
  box-shadow: var(--box-shadow-default);
  border-radius: var(--border-radius-infinite);
  background-color: var(--color-background-emphasis);
  transition: var(--transition-default);
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: -$borderSize;
    top: -$borderSize;
    border-radius: var(--border-radius-infinite);
    border-left: $borderSize solid var(--color-background-accent);
    border-right: $borderSize solid var(--color-background-accent);
    border-top: $borderSize solid transparent;
    border-bottom: $borderSize solid transparent;
    opacity: 0;
    animation: spin 8s infinite linear;
    transition: var(--transition-default);
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% + #{$borderSize * 2});
    height: calc(100% + #{$borderSize * 2});
    border-radius: var(--border-radius-infinite);
    box-shadow: var(--box-shadow-default);
  }
  &:not(.done)::before {
    width: calc(100%);
    height: calc(100%);
  }

  &.done:not(.inProgress)::after {
    border-color: var(--color-background-accent);
    opacity: 1;
  }

  &.done,
  &.inProgress {
    color: var(--color-background-accent);
  }

  &.inProgress::after {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
