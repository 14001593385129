@import 'const';

.stepper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;

  // Step
  > * {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: calc(var(--size-giant) / 2);

    color: var(--color-text-secondary);
    transition: var(--transition-default);

    // Disable line for the 1st
    &:first-child > *:nth-child(1) > *:first-child {
      display: none;
    }

    // Step
    > *:nth-child(1) {
      position: relative;

      &:hover {
        > label {
          transform: scale(0.85);
        }
      }

      // Round digit input
      input {
        display: none;
      }

      // Line
      > *:first-child {
        position: absolute;
        left: calc(-100% + 6px);
        top: 50%;
        border-radius: 3px;
        width: calc(100% - 12px);
        height: 3px;
        background-color: var(--color-background-secondary);
        z-index: 1;
        transition: var(--transition-default);

        // &.done {
        //   background-color: var(--color-background-accent);
        // }
      }

      // Round digit
      > label {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        width: var(--size-giant);
        height: var(--size-giant);
        box-shadow: var(--box-shadow-default);
        border-radius: var(--border-radius-infinite);
        background-color: var(--color-background-emphasis);
        transition: var(--transition-default);
        z-index: 2;

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: -3px;
          top: -3px;
          border-radius: var(--border-radius-infinite);
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          animation: spin 8s infinite linear;
          transition: var(--transition-default);
        }

        // &.done:not(.progress)::after {
        //   border-color: var(--color-background-accent);
        //   opacity: 1;
        // }

        // &.done {
        //   color: var(--color-background-accent);
        // }

        // &.progress::after {
        //   opacity: 1;
        // }
      }
    }

    // Label on the node
    > *:nth-child(2) {
      position: relative;
      max-width: var(--size-giant);
      margin-top: var(--spacing-small);
      font-size: var(--size-text-regular);

      > * {
        width: 200%;
        transform: translateX(-25%);
        text-align: center;
      }
    }

    &.selected,
    &:hover {
      color: var(--color-background-accent);
    }

    &.done {
      label {
        &::after {
          border-top-color: inherit;
          border-bottom-color: inherit;
        }
      }
    }

    &.done,
    &.progress {
      // Line before <label>
      & > *:nth-child(1) > *:first-child {
        background-color: var(--color-text-secondary);
      }

      label {
        // color: var(--color-background-accent);

        &::after {
          // opacity: 1;
          border-left-color: inherit;
          border-right-color: inherit;
        }
      }
    }

    &.selected {
      // Line before <label>
      & > *:nth-child(1) > *:first-child {
        background-color: var(--color-background-accent);
      }
    }
  }

  &:not(.scrollable) {
    justify-content: center;
  }

  &.scrollable {
    // Step
    > *:first-child {
      margin-left: var(--spacing-giant);
    }
    > *:last-child {
      margin-right: var(--spacing-giant);
    }
  }

  &.fontSmall {
    > * > *:nth-child(2) {
      font-size: var(--size-text-small);
    }
  }
}
