.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--full-height);
  text-align: center;

  &.color-red {
    color: var(--color-text-error);
  }

  > * {
    margin: var(--spacing-regular);
  }
}

.iconContainer {
  opacity: 0.3;
}

.errorMessageContainer {
  margin-top: var(--spacing-big);
  margin-bottom: var(--spacing-big);
  font-size: var(--size-text-small);
}
