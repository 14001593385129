@import 'const';

.container {
  max-width: $tabletScreenWidth;
  margin: 0 auto;

  table {
    th {
      text-align: center;
    }

    td:not(:first-child) {
      text-align: center;
    }
  }
}

.table {
  width: 100%;
}

.shrink {
  width: 20px;
}

.notificationsHelpTooltipContent {
  text-align: left;
}
