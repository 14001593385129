@import 'const';

.hiddenOverflow {
  overflow: hidden;
}

.setupStepper {
  margin: var(--spacing-big);
}

.contentContainer {
  max-width: $tabletScreenWidth;
  margin: 0 auto;

  > * {
    position: relative;
    left: 0; // Using left instead of transform allows to avoid tooltip artifacts.
    will-change: left;
    display: flex;
    word-wrap: break-word;
    white-space: nowrap;
    transition: var(--transition-default);

    > * {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-shrink: 0;
      // flex-grow: 0;
      width: 100%;
      opacity: 0;
      transition: var(--transition-default);
      white-space: normal;

      &.selected {
        opacity: 1;
      }

      > * {
        // A piece of magic enabling normal overflow for child elements.
        min-width: 0;
      }
    }
  }
}
