

.alwaysProxyOptions {
  display: inline-flex;
  align-items: center;

  input {
    margin-right: var(--spacing-small);
  }

  &:not(:last-child) {
		.label {
			margin-right: var(--spacing-regular);
		}
  }

	.label {
		user-select: none;
	}
}

.disabled {
	color: var(--color-text-minor);
}