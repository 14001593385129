.container {
  min-height: var(--full-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.center {
  text-align: center;
  margin: var(--spacing-regular);
}

.info {
  color: var(--color-text-minor);
  font-size: var(--size-text-small);
}

.icon {
  transition: transform 0.2s ease-out;
  transform: scale(1);
}

.icon.zoomed-out {
  transform: scale(0);
}

.iconContainer {
  margin: var(--spacing-big);
  opacity: 0.3;
  & + button {
    margin-top: 0;
  }
}
