@import 'const';

$mobileLayoutBreakpoint: $tabletScreenWidth - 200px; // ~800px

.container {
  display: flex;
  justify-content: center;
  > * {
    margin: var(--spacing-small);
    flex: 1;
  }
  @media screen and (max-width: $mobileLayoutBreakpoint) {
    flex-direction: column;
  }
}

.textInfo {
  color: var(--color-text-info);
  vertical-align: baseline;
}

.balance {
  color: var(--color-text-secondary);
  font-weight: normal;
  @media screen and (max-width: $mobileLayoutBreakpoint) {
    position: static;
    display: block;
    margin-left: var(--spacing-giant);
  }
}

.hoverable {
  cursor: pointer;
  &:hover {
    background-color: var(--color-background-selected);
  }
}

.pointer {
  cursor: pointer;
}

.subscriptionInfo {
  font-size: var(--size-text-small);
}
