$borderRadius: var(--border-radius-regular);

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-primary);
  border-radius: $borderRadius;
}

.header {
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  margin: 0;
  padding: var(--spacing-regular);
}

.headerVolumeBased {
  background: var(--background-gradient-variant-1);
}

.headerBusiness {
  background: var(--background-gradient-variant-2);
}

.headerEnterprise {
  background: var(--background-gradient-variant-3);
}

.description {
  margin: var(--spacing-small);
  font-size: var(--size-text-small);
}

.price {
  font-size: var(--size-text-large);
  text-align: center;
}

.feature {
  font-size: var(--size-text-small);
  ul {
    padding-left: var(--spacing-huge);
    margin-bottom: var(--spacing-regular);
  }

  li {
    list-style: none;
    text-align: left;
    margin-bottom: var(--spacing-small);
    &::before {
      content: '💜 ';
    }
  }
}

.selectPlanButton {
  margin-top: auto;
  align-self: center;
  display: block;
  width: 80%;
}

.currentPlan {
  color: var(--color-text-info) !important;
}

hr {
  width: 90%;
  border: 0;
  height: 1px;
  background-color: var(--color-background-secondary);
  text-align: center;
}

.radio {
  display: flex;
  flex-direction: column;
  padding-left: var(--spacing-big);
  margin-bottom: var(--spacing-regular);
}

.volumeBasedExplanation {
  text-align: center;
  padding-bottom: var(--spacing-huge);

  p {
    margin: var(--spacing-small) var(--spacing-regular);
  }

  a {
    font-size: var(--size-text-small);
    &:hover {
      color: var(--color-text-info);
    }
  }
}
