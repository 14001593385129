.centerMinor {
  text-align: center;
  font-size: var(--size-text-small);
  color: var(--color-text-secondary);
  padding: var(--spacing-regular);
}

.error {
  color: var(--color-text-error);
}
