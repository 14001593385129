.setupVerificationStatusBlock {
  display: flex;
  flex-direction: row;
  & > div:first-child {
    color: var(--color-text-minor);
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: var(--spacing-regular);
  }
}

.sub {
  color: var(--color-text-minor);
  font-size: var(--size-text-tiny);
}

.p {
  margin: var(--spacing-big) 0 var(--spacing-regular) 0;
}

.error {
  color: var(--color-text-error);
}

.success {
  color: var(--color-text-success);
}

.center {
  text-align: center;
}

.table {
  font-size: var(--size-text-small);
  width: 100%;
  table-layout: fixed;
  th:last-child,
  td:first-child {
    width: calc(var(--size-big));
  }
}
