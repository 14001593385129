.container {
  box-sizing: border-box;
  overflow: auto;
  width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  background-color: var(--color-background-emphasis);
  box-shadow: var(--box-shadow-inset);
}

.menuCategoryItem {
  font-weight: bold;
  font-size: var(--size-text-regular);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: var(--spacing-regular) var(--spacing-regular) var(--spacing-small)
    var(--spacing-regular);
}
