.pipelineStep {
  display: flex;
  align-items: center;
  margin: var(--spacing-regular);

  > *:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(var(--size-giant) * 1.5);
    min-width: calc(var(--size-giant) * 1.5);
    text-align: center;
    font-size: var(--size-text-small);
    height: var(--size-giant);
    align-self: flex-start;
  }

  > *:nth-child(2) {
    align-self: stretch; /* Makes height 100% so that the child component can draw a full-height line. */
  }

  > *:last-child {
    flex-grow: 1;
  }
}

.card {
  position: relative;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-emphasis);
  box-sizing: border-box;
  transition: var(--transition-default);

  &.expanded {
    border-radius: var(--border-radius-small);
  }
}

.head {
  padding: var(--spacing-regular) var(--spacing-big);
  height: var(--size-giant);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: var(--color-text-minor);
  cursor: pointer;
  transition: var(--transition-default);

  &:hover,
  &.expanded {
    color: var(--color-text-emphasis);
  }
}

.body {
  max-height: 0;
  overflow: hidden;
  box-sizing: border-box;

  transition: all 0.3s ease-in-out;

  &.expanded {
    max-height: 2000px;
  }

  > .bodyContainer {
    margin: var(--spacing-regular);

    .buttonsBar {
      text-align: right;
      margin-top: var(--spacing-regular);
    }
  }
}

.statusBox {
  display: flex;
  align-items: center;

  &:last-child {
    width: 100px;
    min-width: 100px;
  }
}

.green {
  color: var(--color-text-success);
}

.red {
  color: var(--color-text-error);
}

.orange {
  color: var(--color-text-warning);
}

.minor {
  color: var(--color-text-minor);
}

.tiny {
  font-size: var(--size-text-tiny);
}

.emphasis {
  color: var(--color-text-emphasis);
}

.flexGrow {
  flex-grow: 1;
  margin-right: var(--spacing-big);
}

.greenBlinking {
  animation: blinkToGreen 2s infinite linear;
}

@keyframes blinkToGreen {
  0% {
    color: inherit;
  }
  30% {
    color: var(--color-text-success);
  }
  40% {
    color: var(--color-text-success);
  }
  70% {
    color: inherit;
  }
  100% {
    color: inherit;
  }
}
