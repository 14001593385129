.container {
  margin: var(--spacing-regular);
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  > thead tr {
    cursor: pointer;
    color: var(--color-text-emphasis);

    th > * {
      vertical-align: middle;
    }

    .padOnHover {
      padding-left: 0;
      transition: var(--transition-default);
    }

    &:hover {
      .padOnHover {
        padding-left: var(--spacing-small);
      }
    }
  }

  > tbody > tr {
    > td:first-child {
      white-space: nowrap;
      color: var(--color-text-emphasis);
    }
    > td:last-child {
      font-family: monospace;
      white-space: normal;
      word-break: break-all;
    }
  }
}

.error {
  color: var(--color-text-error);
}

.tinyText {
  font-size: var(--size-text-tiny);
}
