.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: var(--full-height);
  text-align: center;

  input {
    box-sizing: border-box;
    height: var(--size-giant);
    width: calc(var(--size-giant) * 6 + var(--spacing-regular) * 2);
    &[type='email'] {
      width: calc(var(--size-giant) * 5);
    }
  }

  button {
    height: var(--size-giant);
  }
}

.inputWithButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fullWidth {
  width: calc(var(--size-giant) * 6 + var(--spacing-regular) * 2);
}

.subTitle {
  font-size: var(--size-text-small);
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-big);
}

.orLine {
  position: relative;
  font-size: var(--size-text-huge);
  color: var(--color-text-secondary);
  padding: var(--spacing-regular);
  font-weight: bold;
  text-align: center;

  &:after,
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 30%;
    left: 5%;
    top: 50%;
    height: 1px;
    background-color: var(--color-text-minor);
  }

  &:after {
    left: auto;
    right: 5%;
  }
}

.footer {
  padding: var(--spacing-big) var(--spacing-regular) 0 var(--spacing-regular);
  color: var(--color-text-minor);
  font-size: var(--size-text-small);
}

.statusMessage {
  opacity: 1;
  margin: var(--spacing-big) var(--spacing-regular) var(--spacing-regular)
    var(--spacing-regular);
  padding: var(--spacing-regular);
  border-radius: var(--border-radius-big);
  color: var(--color-text-info);
  &.statusMessageHidden {
    margin: 0;
    padding: 0;
    height: 0;
    opacity: 0;
  }
  > * {
    vertical-align: middle;
  }
}

.error {
  color: var(--color-text-error);
}
