.summaryTable {
  font-size: var(--size-text-small);
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    display: flex;
    align-items: center;
  }
}

.accent {
  font-size: var(--size-text-big);
}

.emphasis {
  color: var(--color-text-emphasis);
}

.bytesInput {
  width: var(--size-mega-giant);
  margin-right: 0;
  margin-left: 0;
}

.buttons {
  text-align: right;
}
