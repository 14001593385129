.enabled {
  color: var(--color-text-success);
}

.disabled {
  color: var(--color-text-secondary);
}

.error {
  color: var(--color-text-error);
}

.noWrap {
  white-space: nowrap;
}

.hostName {
  white-space: nowrap;
  > * {
    vertical-align: middle;
  }
}

.specialControlsIcon {
  color: var(--color-text-minor);
}

.serviceTableRow {
  transition: var(--transition-default);
  &.selectedRow {
    background-color: var(--color-background-success);
  }
  &:not(.serviceControlsRow) {
    user-select: none;
    cursor: pointer;
    &:hover {
      background-color: var(--color-background-selected);
    }
  }
  > td:first-child {
    font-family: var(--text-font-family-monospace);
  }
}
