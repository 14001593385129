.error {
  color: var(--color-text-error);
}

.minor {
  color: var(--color-text-minor);
}

.success {
  color: var(--color-text-success);
}

.warning {
  color: var(--color-text-warning);
}

.errorContainer {
  color: var(--color-text-error);
  margin-top: var(--spacing-regular);
}

.row {
  &.withChildren {
    transition: var(--transition-default);
    cursor: pointer;
    &:hover {
      background-color: var(--color-background-selected);
    }
    &.selected {
      background-color: var(--color-background-success);
    }
  }
}
