@import 'const';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--full-height);
  text-align: center;

  > * {
    margin: var(--spacing-regular);
  }
}

.content {
  max-width: $maxReadableBlock;
}

.feedbackLine {
  display: flex;
  justify-content: space-between;
}

.stars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > *:first-child {
    > * {
      margin: 0 var(--spacing-regular) 0 0;
      color: var(--color-text-minor);
      &:first-child {
        margin-left: var(--spacing-regular);
      }
      &.active {
        color: var(--color-background-accent);
      }
    }
  }

  > *:last-child {
    font-size: var(--size-text-small);
    color: var(--color-text-minor);
  }
}

.secondary {
  color: var(--color-text-secondary);
}
