.topText {
  padding: var(--spacing-giant) var(--spacing-regular) var(--spacing-regular)
    var(--spacing-regular);
  color: var(--color-text-minor);
  font-size: var(--size-text-small);
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-regular) var(--spacing-small);

  > * {
    width: 100%;
    max-width: calc(min(100vw, 800px));
    flex-grow: 1;
  }

  > * > h3 {
    margin-left: var(--spacing-regular);
    margin-top: var(--spacing-huge);
  }
}
