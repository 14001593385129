.container {
  display: flex;
  justify-content: center;

  > * {
    margin: 0 var(--spacing-tiny);
    padding: var(--spacing-regular);
    padding-bottom: var(--spacing-small);
    font-size: var(--size-text-regular);
    border-bottom: 3px solid transparent;
    cursor: pointer;
    transition: var(--transition-default);

    &.selected {
      font-weight: bold;
      border-bottom: 3px solid var(--color-text-primary);
    }

    &:hover {
      color: var(--color-text-emphasis);
      border-bottom-color: var(--color-text-minor);

      &.selected {
        border-bottom-color: var(--color-text-emphasis);
      }
    }

    .stabilizer {
      position: relative;

      > *:first-child {
        visibility: hidden;
        font-weight: bold;
      }

      > *:last-child {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        text-align: center;
      }
    }
  }
}
