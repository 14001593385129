.container {
  overflow: auto;
}

.compact {
  font-size: var(--size-text-small);

  th,
  td {
    padding: var(--spacing-small);
  }
}
