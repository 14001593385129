.card {
  position: relative;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-background-emphasis);
  margin: var(--spacing-regular);
  box-sizing: border-box;
  z-index: 10;

  transition: var(--transition-default);

  .head {
    padding: var(--spacing-regular);
    display: flex;
    align-items: center;
    color: var(--color-text-minor);
    cursor: pointer;
    transition: var(--transition-default);

    .displayedUrl {
      word-break: break-word;
      font-weight: bold;
    }

    &.healthy {
      color: var(--color-text-primary);
    }

    &:hover {
      color: var(--color-text-emphasis);
    }
  }

  &.expanded {
    border-radius: var(--border-radius-small);

    .head {
      color: var(--color-text-emphasis);
    }
  }
}

.quickStatusBox {
  display: flex;
  align-items: center;

  > ul {
    margin: 0;
  }

  li > ul {
    margin: 0;
    padding: 0;
  }
}

.lockIcon {
  transition: var(--transition-default);
}

.green {
  color: var(--color-text-success);
}

.red {
  color: var(--color-text-error);
}

.minor {
  color: var(--color-text-minor);
}

.emphasis {
  color: var(--color-text-emphasis);
}

.flexGrow {
  flex-grow: 1;
  margin-right: var(--spacing-big);
}

.greenBlinking {
  animation: blinkToGreen 2s infinite linear;
}

@keyframes blinkToGreen {
  0% {
    color: inherit;
  }
  30% {
    color: var(--color-text-success);
  }
  40% {
    color: var(--color-text-success);
  }
  70% {
    color: inherit;
  }
  100% {
    color: inherit;
  }
}

.shortEndpointStateBox {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
}

.body {
  max-height: 0;
  overflow: hidden;
  box-sizing: border-box;

  transition: var(--transition-default);

  &.expanded {
    max-height: 1000px;
  }

  > .bodyContainer {
    margin: var(--spacing-regular);

    .buttonsBar {
      text-align: right;
      margin-top: var(--spacing-regular);
    }
  }
}

.setupGuideContainer {
  font-size: var(--size-text-small);

  > *:first-child {
    margin: var(--size-regular);
    text-align: center;

    > *:first-child {
      margin-bottom: var(--spacing-big);
      color: var(--color-text-minor);
    }
  }

  > *:last-child {
    table {
      margin: 0 auto;
    }

    tbody tr:first-child td {
      font-weight: bold;
    }
  }
}

.tiny {
  font-size: var(--size-text-tiny);
}

.statsTable {
  font-size: var(--size-text-small);

  tr {
    td:first-child {
      white-space: nowrap;
      font-weight: bold;
    }
  }
}

.endpointChecksBlock {
  margin-top: var(--spacing-regular);
}

.ulBlacklisted {
  margin: 0;
  padding-left: var(--spacing-huge);
}
