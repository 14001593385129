@import 'const';

.toolbarWrapper {
  white-space: nowrap;
  > * {
    vertical-align: middle;
  }
}

.endpointBox {
  text-align: center;

  & > table {
    margin: 0 auto;
  }
}

.info {
  color: var(--color-text-secondary);
  font-size: var(--size-text-small);
}

.sub {
  color: var(--color-text-minor);
  font-size: var(--size-text-tiny);
}

.red {
  color: var(--color-text-error);
}

.p {
  margin: var(--spacing-big) 0 var(--spacing-regular) 0;
}

.center {
  text-align: center;
}

.twoCols {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.monospace {
  font-family: monospace;
}

.green {
  color: var(--color-text-success);
}

.red {
  color: var(--color-text-error);
}

.settingsContainer {
  td:first-child {
    text-align: center;
  }
  td > * {
    margin: 0;
  }
  margin-top: var(--spacing-regular);
}

.scriptCode {
  font-size: var(--size-text-small);
}

.warnsBlock {
  margin: var(--spacing-huge) var(--spacing-regular) var(--spacing-regular)
    var(--spacing-regular);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--size-text-small);
  color: var(--color-text-secondary);

  > * {
    > * {
      display: flex;
      align-items: center;
    }
  }
}

.footer {
  margin-top: var(--spacing-huge);
  text-align: center;
}

.endpointConfigBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: var(--spacing-big);

    select {
      margin: 0;
    }
  }
}
