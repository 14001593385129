.textInput {
  position: relative;

  .input {
    margin: var(--spacing-regular);
  }

  label {
    display: block;
    overflow: visible;
    height: calc(var(--size-text-small) / 2);
    padding-left: calc(var(--spacing-regular) + var(--spacing-big));
    font-size: var(--size-text-small);
  }

  &.inline {
    display: inline-flex;
  }
  &.compact {
    .input {
      padding: var(--spacing-small) var(--spacing-regular);
      font-size: var(--size-text-regular);
    }
    label {
      padding-left: calc(var(--spacing-regular) * 2);
    }
  }
  &.wide {
    flex-grow: 1;
    .input {
      width: calc(100% - var(--spacing-regular) * 2);
    }
  }
}
