.customTooltipContainer {
  display: block;
  font-size: var(--size-text-small);

  > div {
    background-color: var(--color-background-emphasis);
    color: var(--color-text-primary);
    box-shadow: var(--box-shadow-default);
    border-radius: var(--border-radius-small);
    padding: var(--spacing-regular);
  }

  .tooltipTime {
    color: var(--color-text-secondary);
    font-weight: bold;
    margin-bottom: var(--spacing-regular);
    font-size: var(--size-text-small);
    text-align: center;
  }

  thead {
    font-weight: bold;
  }
}
