// Most icons source: https://simpleicons.org

.icon {
  position: relative;
  padding: 0;
  box-sizing: border-box;
  align-self: auto;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-default);
  > svg {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &.shadow {
    filter: drop-shadow(var(--box-shadow-intensive));
  }
}

.margin-s {
  margin: 0 var(--spacing-s) 0 var(--spacing-s);
}

.margin-regular {
  margin-left: var(--spacing-regular);
  margin-right: var(--spacing-regular);
}

.margin-small {
  margin-left: var(--spacing-small);
  margin-right: var(--spacing-small);
}

.margin-right-regular {
  margin-right: var(--spacing-regular);
}

.margin-right-tiny {
  margin-right: var(--spacing-tiny);
}

.margin-center {
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.margin-left-regular {
  margin-left: var(--spacing-regular);
}

.margin-left-small {
  margin-left: var(--spacing-small);
}

.margin-right-small {
  margin-right: var(--spacing-small);
}

.margin-none {
  margin: 0;
}

.svgRounded > svg {
  width: 70%; // Sin 45 deg
  height: 70%;
}

.rounded {
  border-radius: 1000px;
  box-shadow: var(--box-shadow-default);
  @extend .svgRounded;
}

.hoverable {
  cursor: pointer;
  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    // margin: 0 14px 0 14px;
    transform: var(--transform-hover-default);
    color: var(--color-text-info);
  }
  user-select: none;
}

.size-xxs {
  width: var(--size-xxs);
  min-width: var(--size-xxs);
  height: var(--size-xxs);
  min-height: var(--size-xxs);
}

.size-xs {
  width: var(--size-xs);
  min-width: var(--size-xs);
  height: var(--size-xs);
  min-height: var(--size-xs);
}

.size-small {
  width: var(--size-small);
  min-width: var(--size-small);
  height: var(--size-small);
  min-height: var(--size-small);
}

.size-regular {
  width: var(--size-regular);
  min-width: var(--size-regular);
  height: var(--size-regular);
  min-height: var(--size-regular);
}

.size-big {
  width: var(--size-big);
  min-width: var(--size-big);
  height: var(--size-big);
  min-height: var(--size-big);
}

.size-huge {
  width: var(--size-huge);
  min-width: var(--size-huge);
  height: var(--size-huge);
  min-height: var(--size-huge);
}

.accent-minor {
  color: var(--color-text-minor);
}

.size-giant {
  width: var(--size-giant);
  min-width: var(--size-giant);
  height: var(--size-giant);
  min-height: var(--size-giant);
}

.size-mega-giant {
  width: var(--size-mega-giant);
  min-width: var(--size-mega-giant);
  height: var(--size-mega-giant);
  min-height: var(--size-mega-giant);
}

.size-ultra-giant {
  width: var(--size-ultra-giant);
  min-width: var(--size-ultra-giant);
  height: var(--size-ultra-giant);
  min-height: var(--size-ultra-giant);
}

.size-xxxl {
  width: var(--size-xxxl);
  min-width: var(--size-xxxl);
  height: var(--size-xxxl);
  min-height: var(--size-xxxl);
}
