.textInput {
  position: relative;

  select {
    margin: var(--spacing-regular);
  }

  label {
    display: block;
    overflow: visible;
    height: calc(var(--size-text-small) / 2);
    padding-left: calc(var(--spacing-regular) + var(--spacing-big));
    font-size: var(--size-text-small);
  }

  &.inline {
    display: inline-flex;
  }
  &.compact {
    select {
      padding: var(--spacing-small)
        calc(var(--spacing-big) + var(--spacing-small)) var(--spacing-small)
        var(--spacing-regular);
      background-position-x: calc(100% - var(--spacing-small));
      font-size: var(--size-text-regular);
    }
    label {
      padding-left: calc(var(--spacing-regular) * 2);
    }
  }
  &.wide {
    flex-grow: 1;
    select {
      width: calc(100% - var(--spacing-regular) * 2);
    }
  }
}
