.container {
  min-height: var(--full-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > * {
    max-width: 600px;
  }
}

.info {
  color: var(--color-text-minor);
  font-size: var(--size-text-small);
  margin-top: var(--spacing-regular);
}

.topInfo {
  color: var(--color-text-minor);
  font-size: var(--size-text-small);
  margin-bottom: var(--spacing-regular);
}

.center {
  text-align: center;
}

.iconsBlock {
  padding: var(--spacing-big);

  a {
    text-decoration: none;
  }
}
