.container {
  align-self: flex-start;
  width: 100%;
}

.header {
  margin-bottom: var(--spacing-big);
}

.margin {
  margin: var(--spacing-regular);
}

.headerTitle {
  > * {
    vertical-align: middle;
  }
}

.enabled {
  color: var(--color-text-success);
}

.disabled {
  color: var(--color-text-secondary);
}

.disabledRed {
  color: var(--color-text-error);
}

.statusBar {
  display: inline-flex;
  align-items: center;
  float: right;

  .statusBarStatuses {
    display: table;
    font-size: small;
    text-align: right;
    margin-right: var(--spacing-small);

    > * {
      display: table-row;
      > * {
        display: table-cell;
        vertical-align: middle;

        &:last-child {
          text-align: left;
        }
      }
    }
  }
}
