@import 'const';

$mobileLayoutBreakpoint: $tabletScreenWidth - 200px; // ~800px

.anchorLineBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-secondary);

  &.ok {
    color: var(--color-background-accent);
  }
}

.container {
  display: flex;
  flex-grow: 1;

  align-items: center;
  justify-content: center;

  > *:first-child,
  > *:last-child {
    transition: var(--transition-default);
    max-width: 150px;
    opacity: 1;
  }

  > *:first-child > * {
    margin-right: var(--spacing-giant);
  }

  > *:last-child > * {
    margin-left: var(--spacing-giant);
  }
}

.footer {
  text-align: center;
  margin: var(--spacing-huge);

  > * {
    vertical-align: top;
  }
}

@media screen and (max-width: $mobileLayoutBreakpoint) {
  .container {
    > *:first-child,
    > *:last-child {
      max-width: 0;
      opacity: 0;
    }
  }
}

.mainContainer {
  max-width: calc(min(100vw, 600px));
  flex-grow: 1;
}
