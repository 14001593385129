.regularPadding {
  padding: var(--spacing-regular);
}

.tableLayout {
  display: table;

  > * {
    display: table-row;

    > * {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-regular) var(--spacing-small);

  > * {
    width: 100%;
    max-width: calc(min(100vw, 800px));
    flex-grow: 1;
  }

  > * > h3 {
    margin-left: var(--spacing-regular);
    margin-top: var(--spacing-huge);
  }
}

.stepsContainer {
  margin-bottom: var(--spacing-giant);
}

.topText {
  padding: var(--spacing-giant) var(--spacing-regular) var(--spacing-regular)
    var(--spacing-regular);
  color: var(--color-text-minor);
  font-size: var(--size-text-small);
  box-sizing: border-box;
}

.controlsBar {
  display: flex;
  align-items: center;

  > *:nth-child(1) {
    display: flex;
    flex-grow: 1;
  }
  > *:nth-child(2) {
    flex-grow: 1;
  }
}

.mainControls {
  display: flex;
  align-items: center;
}
