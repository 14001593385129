$dashsize: 8;
$holesize: 5;

.svgLines {
  height: 1px;
  width: 1px;
  display: block;
  overflow: visible;
  pointer-events: none;
  z-index: 0;

  path {
    stroke: var(--color-text-minor);
    stroke-width: 3px;
    stroke-dasharray: #{$dashsize - $holesize} $holesize;
    stroke-linecap: round;
    fill: none;
    z-index: 0;
    opacity: 0;
    animation: brokenLink 4s infinite linear;

    &.use {
      opacity: 1;
      z-index: 1;
      animation: none;

      &.ok {
        stroke: var(--color-background-accent);
        animation: dashAnimation 1s infinite linear;
        z-index: 2;
      }
    }
  }
}

@keyframes dashAnimation {
  to {
    stroke-dashoffset: -$dashsize;
  }
}

@keyframes brokenLink {
  0% {
    opacity: 0.04;
  }
  10% {
    opacity: 0.3;
  }
  20% {
    opacity: 0.1;
  }
  35% {
    opacity: 0.5;
  }
  42% {
    opacity: 0.05;
  }
  59% {
    opacity: 0.8;
  }
  76% {
    opacity: 0.4;
  }
  82% {
    opacity: 0.75;
  }
  94% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.04;
  }
}
