.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--full-height);
  text-align: center;

  > * {
    margin: var(--spacing-regular);
  }
}

.iconContainer {
  color: var(--color-text-error);
  opacity: 0.3;
}

.errorMessageContainer {
  margin-top: var(--spacing-big);
  font-size: var(--size-text-small);
  color: var(--color-text-error);
}
