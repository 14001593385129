.margin {
  margin: var(--spacing-regular);
}

.hint {
  color: var(--color-text-warning);
  font-size: var(--size-text-small);
  > * {
    vertical-align: middle;
  }
}
