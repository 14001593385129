.container {
  position: relative;
  overflow-x: auto;
}

.topTotalRow th {
  // background-color: var(--color-background-success) !important;
  font-weight: normal;
}

.error {
  color: var(--color-text-error);
}

.table {
  width: 100%;
  font-size: var(--size-text-small);

  & > thead > tr > th,
  & > tbody > tr > td {
    padding: var(--spacing-small);
  }

  & > thead > tr > th {
    font-size: var(--size-text-small);
  }
}

.minorTextContainer {
  text-align: center;
  color: var(--color-text-secondary);
  font-size: var(--size-text-small);
}

.enabled {
  color: var(--color-text-success);
}

.disabled {
  color: var(--color-text-secondary);
}

.minor {
  color: var(--color-text-minor);
}
