.inline {
  appearance: none;
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: inherit;
  vertical-align: text-bottom;
}

.label {
  background: none;
  padding: 0;
}

.hoverable {
  display: inline-block;

  &:hover {
    color: var(--color-text-emphasis);
  }
}