.selectContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    & > div:first-child {
      position: relative;
      margin: var(--spacing-regular);
      select {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.statusTextLine {
  text-align: center;
}
